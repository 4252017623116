import styles from './GroupEntry.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useStores } from 'stores';

const NewDomainAlert = ({ t, lang }) => {
  const [show, setShow] = useState(false);
  const explorerUrl = 'https://cloverpool.com';

  let langChange = {
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-cn',
  };
  let hcLang = langChange[lang] ? langChange[lang] : 'en-us';
  const activityUrl = `https://help.cloverpool.com/hc/${hcLang}/articles/37699204257945`;
  const getStorageItem = (key) => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }
  };
  const setStorageItem = (key, value) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, value);
    }
  };
  useEffect(() => {
    if (getStorageItem('newDomainAlert') === 'true') {
      return;
    }
    setStorageItem('newDomainAlert', 'true');
    setShow(true);
  }, []);
  if (!show) {
    return <></>;
  }
  return (
    <Modal
      wrapClassName={styles['newDomainAlert']}
      open={show}
      onCancel={() => setShow(false)}
      maskClosable={false}
      width="400px"
      centered
      title={
        <div className="flex justify-center items-center">
          <div className={`text-center ${styles['newDomainAlert-title']}`}>
            {t('common:topNav.newDomainAlert.title', { lng: lang })}
          </div>
        </div>
      }
      footer={null}
    >
      <div title="">
        <div className={styles['newDomainAlert-desc']}>
          <div>{t('common:topNav.newDomainAlert.content1')}</div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content2" // optional -> fallbacks to defaults if not provided
              components={[<span key={1} style={{ fontWeight: 'bold' }} />]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content3" // optional -> fallbacks to defaults if not provided
              values={{ link: activityUrl }}
              components={[
                <a
                  target="_blank"
                  href={activityUrl}
                  key={0}
                  rel="noreferrer"
                />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content4" // optional -> fallbacks to defaults if not provided
              values={{ link: explorerUrl }}
              components={[
                <span key={2} style={{ fontWeight: 'bold' }} />,
                <a
                  target="_blank"
                  href={explorerUrl}
                  key={0}
                  rel="noreferrer"
                />,
                <span key={1} style={{ fontWeight: 'bold' }} />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
        </div>
      </div>
      <div className={styles['newDomainAlert-btn-wrap']}>
        <Button
          type="primary"
          size="md"
          shape="round"
          className={styles['newDomainAlert-btn']}
          onClick={() => setShow(false)}
        >
          {t('common:topNav.newDomainAlert.btn')}
        </Button>
      </div>
    </Modal>
  );
};
const GroupEntry = () => {
  const { t } = useTranslation('common');
  const { appStore: store } = useStores();
  const { lang } = store;
  return (
    <>
      <div className={`${styles['group-entry']} ${styles['xs-hide']}`}>
        <div className={styles['wordContaner']}>
          {t('topNav.viewAndCollectDesc')}
        </div>
        <NewDomainAlert t={t} lang={lang} />
      </div>
    </>
  );
};
export default GroupEntry;
