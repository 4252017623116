import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { LOCALES_MAP } from 'common/config/localeConfig';
import { Popover, Menu, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import queryString from 'qs';
import LoginButtons from './LoginButtons';
import Ts from 'components/LocaleTranslator';
import { withTranslation } from 'react-i18next';

// import { Alert } from 'antd';
import './index.scss';
import { upperCase } from 'common/utils';
import SvgIcon from '../SvgIcon';
import { BetaIcon, LoadingWrap, Activity } from 'components/widget';

const LangMenuItem = ({ showShort, text, short }) => {
  return (
    <>
      <span>{text}</span>
      {showShort && <span className="lang-item-short">{upperCase(short)}</span>}
    </>
  );
};

const USER_CENATER_URL = process.env.REACT_APP_USER_CENTER;

@withRouter
@inject('store')
@observer
class OpRegion extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.inviteStore = this.props.store.inviteStore;

    const userIntroHide = Cookie.get('hiddenPoolManageIntro');

    this.state = {
      introVisible: !!!userIntroHide,
    };
  }

  getLangDropdown() {
    const { lang } = this.appStore;

    const menu = (
      <Menu
        className="lang-dropdown-menu"
        onClick={(e) => this.handleLangMenuClick(e.domEvent, e.key)}
      >
        {Object.keys(LOCALES_MAP).map((k) => {
          const item = LOCALES_MAP[k];
          return (
            <Menu.Item key={item.key}>
              <LangMenuItem {...item} showShort />
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const selectLangItem = LOCALES_MAP[lang || 'en'];
    return (
      <Popover
        content={menu}
        overlayClassName="lang-popover common-menu-popover"
      >
        <a
          className="lang-dropdown nav-dropdown"
          onClick={(e) => e.preventDefault()}
        >
          <LangMenuItem {...selectLangItem} />
          <SvgIcon
            iconName="icon_menu_arrow_down"
            size="menuArrow"
            className="menu-arrow"
          />
        </a>
      </Popover>
    );
  }

  handleLangMenuClick = (event, key) => {
    const { lang } = this.appStore;
    const { location, history } = this.props;

    let pathWithLang = false;
    Object.keys(LOCALES_MAP).forEach((lang) => {
      const lowerLangPath = `/${lang.toLowerCase()}`;
      const langPath = `/${lang}`;
      const selectPath = `/${key}`;
      if (
        this.props.location.pathname?.toLowerCase().startsWith(lowerLangPath)
      ) {
        pathWithLang = true;
        this.appStore.setLocaleLang(key);
        this.props.i18n.changeLanguage(key);
        history.replace(
          `${location.pathname.replace(new RegExp(langPath, 'i'), selectPath)}${
            location.search
          }`,
        );
        return;
      }
    });

    if (lang === key || pathWithLang) return;
    this.appStore.setLocaleLang(key);
    this.props.i18n.changeLanguage(key);
  };

  getAccountName(userInfo) {
    if (userInfo) {
      if (userInfo.phone && userInfo.phone.number) {
        return userInfo.phone.number;
      } else {
        return userInfo.mail;
      }
    }
  }

  logout = () => {
    this.userStore.logout();
    this.props.history.push('/');
  };

  /**
   * 跳转到邀请体系
   * 先判断用户是否绑定手机号，如果没有则弹窗提示
   * 如果已经绑定手机号，则调用接口生成邀请专属子账户，成功后跳转到邀请页面
   */
  turnToInvite = (e) => {
    if (this.props.location.pathname === '/invite') {
      window.location.reload();
      e.preventDefault();
      return;
    }
  };

  getLoginInfoPop() {
    const userInfo = this.userStore.userContactInfo;
    return (
      <div className="login-info-pop">
        <div className="login-info-pop-text">
          <span>{this.getAccountName(userInfo)}</span>
          {userInfo.uid !== 0 && (
            <span className="uid">UID: {userInfo.uid}</span>
          )}
        </div>
        <div className="login-info-pop-line" />
        <div className="login-info-pop-btns">
          <a
            // onClick={this.logout}
            target="_blank"
            rel="noopener noreferrer"
            href={USER_CENATER_URL}
            className="login-info-pop-btn"
          >
            <Ts id="common.header.accountSetting" />
          </a>
          <a
            onClick={this.turnToInvite}
            className="login-info-pop-btn"
            href="/invite"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Ts id="invite.inviteTitle" />
            <Activity size="sm" className="margin-left-sm" />
            {/* <BetaIcon size="sm" className="margin-left-sm" /> */}
            {/* <SvgIcon iconName="icon_hot" className="margin-left-sm" /> */}
          </a>
          <a
            href="/guardian"
            target="_blank"
            rel="noopener noreferrer"
            className="login-info-pop-btn"
          >
            <Ts id="pages.settings.shareAccountsSetting" />
            <Tooltip title={<Ts id="pages.settings.shareDataTip" />}>
              <span className="share-icon">
                <SvgIcon iconName="icon_explanation_normal"></SvgIcon>
              </span>
            </Tooltip>
          </a>
          <a onClick={this.logout} className="login-info-pop-btn">
            <Ts id="common.header.SignOut" />
          </a>
        </div>
      </div>
    );
  }

  getAuthenticatedUserDisplay(isUserReadOnly) {
    if (isUserReadOnly) {
      return null;
    }

    return (
      <>
        <Popover
          content={this.getLoginInfoPop()}
          overlayClassName="login-popover common-menu-popover nav-popover"
        >
          <span className="avatar nav-dropdown">
            <SvgIcon iconName="icon_avatar" className="icon-avatar" />
            <SvgIcon
              iconName="icon_menu_arrow_down"
              size="menuArrow"
              className="menu-arrow"
            />
          </span>
        </Popover>
      </>
    );
  }

  getSearchParams(isUserReadOnly, isWatcher, isShared, accountInfo) {
    let search = '';
    if (isUserReadOnly) {
      let searchParams = {};
      searchParams = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (isWatcher) {
        if (searchParams.access_key) {
          search = queryString.stringify(
            { access_key: searchParams.access_key, puid: searchParams.puid },
            { addQueryPrefix: true },
          );
        }
      } else if (isShared) {
        if (searchParams.puid) {
          search = queryString.stringify(
            { puid: searchParams.puid, shared: searchParams.shared },
            { addQueryPrefix: true },
          );
        }
      }
    } else if (accountInfo.puid) {
      // 正常模式下，url携带当前puid
      search = queryString.stringify(
        { puid: accountInfo.puid },
        { addQueryPrefix: true },
      );
    }
    return search;
  }

  toggleIntro = () => {
    this.setState((state) => {
      const newstate = !state.introVisible;
      if (!newstate) {
        Cookie.set('hiddenPoolManageIntro', true, { expires: 365 * 10 });
      }
      return {
        introVisible: newstate,
      };
    });
  };

  render() {
    const {
      isAuthenticated,
      accountInfo,
      isWatcher,
      isShared,
      isUserReadOnly,
      fetchingSubAccount,
    } = this.userStore;
    const { isPoolManageActive } = this.props;

    const search = this.getSearchParams(
      isUserReadOnly,
      isWatcher,
      isShared,
      accountInfo,
    );

    return (
      <div className="flex items-center">
        {isAuthenticated && (
          <Popover
            content={
              <div className="intro-tip-content">
                <Ts id="common.header.introTip" />
                <CloseOutlined
                  className="intro-tip-close"
                  onClick={this.toggleIntro}
                />
                {/* <Icon type="close" /> */}
              </div>
            }
            // visible={this.state.introVisible && !isWatcher}
            visible={false}
            overlayClassName="login-intro-tip"
          >
            {!isWatcher ? (
              <div
                className={`pool-manage-btn ${
                  isPoolManageActive ? 'noWatcher active' : ''
                }`}
              >
                <LoadingWrap loading={fetchingSubAccount}>
                  <Link
                    to={{
                      pathname: '/dashboard',
                      search: search,
                    }}
                  >
                    <Ts id="common.header.poolManagePanel" />
                  </Link>
                </LoadingWrap>
              </div>
            ) : (
              <div className={`pool-manage-btn active isWatcher`}>
                <Ts id="common.header.watcherMode" />
                <a href="/" className="watcherExit">
                  <Ts id="common.header.SignOut" />
                </a>
              </div>
            )}
          </Popover>
        )}

        {!isAuthenticated ? (
          <LoginButtons />
        ) : (
          this.getAuthenticatedUserDisplay(isUserReadOnly)
        )}

        <div>{this.getLangDropdown()}</div>
      </div>
    );
  }
}
export default withTranslation()(OpRegion);
